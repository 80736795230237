import React, { useRef, useEffect } from 'react';
import styles from './Page.module.scss';
import { Link } from 'gatsby';

type Props = {
  title?: string,
  children: React.Node,
  isIndex: ?boolean
};

const Page = ({ title, children, isIndex }: Props) => {
  const pageRef = useRef('PageTop');

  if (isIndex == true){
  } else {
    useEffect(() => {
      pageRef.current.scrollIntoView();
    });
  }

  return (
    <div ref={pageRef} className={styles['page']}>
      <div className={styles['page__inner']}>
        { title && <h1 className={styles['page__title']}>{title}</h1>}
        <div className={styles['page__body']}>
          {children}
        </div>
        <Link className={styles['page__top-button']} onClick={() => scrollTo('html')} to="">To top</Link>
      </div>
    </div>
  );
};

export default Page;